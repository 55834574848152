export function useStringHelpers() {
  const createUrlFromFilters = (searchAdvancedFilters: any) => {
    let url = '/espacos'
    const query: any = {}

    if (searchAdvancedFilters.city) {
      url = `${url}/${searchAdvancedFilters.city}`
    }

    if (searchAdvancedFilters.neighborhood) {
      url = `${url}/${searchAdvancedFilters.neighborhood}`
    }

    query.possuiwebsite = searchAdvancedFilters.hasWebsite
      ? searchAdvancedFilters.hasWebsite
      : false

    query.avaliacaominima = isNaN(searchAdvancedFilters.minRating)
      ? 0
      : searchAdvancedFilters.minRating
    query.avaliacaomaxima = isNaN(searchAdvancedFilters.maxRating)
      ? 0
      : searchAdvancedFilters.maxRating

    url = removeSpecialCharsFromText(url.replace(/ /g, '-').toLowerCase())

    return `${url}?${expandQueryParams(query)}`
  }

  const expandQueryParams = (queryParams: any) => {
    return `possuiwebsite=${queryParams.possuiwebsite}&avaliacaominima=${queryParams.avaliacaominima}&avaliacaomaxima=${queryParams.avaliacaomaxima}`
  }

  const normalizeStringForUrl = (string: string) => {
    return string.replace(/ /g, '-').toLowerCase()
  }

  const normalizeStringForValue = (string: string) => {
    if (!string) return string
    return string
      .replace(/-/g, ' ')
      .split(' ')
      .map((word) =>
        word.length > 2
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word
      )
      .join(' ')
  }

  const truncateString = (string: string, maxLength: number) => {
    if (string.length > maxLength) {
      return string.slice(0, maxLength) + '...'
    }
    return string
  }

  const getCloudinaryImageName = (imageUrl: string) => {
    const parts = imageUrl.split('/')
    const lastThreeParts = parts.slice(-3)
    return lastThreeParts.join('/')
  }

  const removeSpecialCharsFromText = (text: string): string => {
    if (text) {
      text = text.replace('/[áàãâä]/ui', 'a')
      text = text.replace('/[éèêë]/ui', 'e')
      text = text.replace('/[íìîï]/ui', 'i')
      text = text.replace('/[óòõôö]/ui', 'o')
      text = text.replace('/[úùûü]/ui', 'u')
      text = text.replace('/[ç]/ui', 'c')
      text = text.replace('/[^a-z0-9]/i', '_')
      text = text.replace('/_+/', '_')
      text = text.replace(/&/g, '')
    }
    return text
  }

  const capitalize = (text: string): string => {
    if (!text) return ''
    text = text.toString().trimEnd().trimStart()
    const words = text.split(' ')

    for (let i = 0, x = words.length; i < x; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1)
    }

    return words.join(' ')
  }

  const removeMaskFromPrice = (price: string): string => {
    return price.replace('.', '').replace(',', '.').replace('R$', '')
  }

  return {
    normalizeStringForUrl,
    normalizeStringForValue,
    truncateString,
    getCloudinaryImageName,
    createUrlFromFilters,
    removeSpecialCharsFromText,
    capitalize,
    removeMaskFromPrice,
  }
}
